import React, { useEffect, useState, useRef } from 'react';
import {
  Button,
  Container,
  Mask,
  Modal,
  ModalHeader,
  ModalBody,
} from 'mdbreact';
import { useNavigate, useLocation } from 'react-router-dom';
import { v4 } from 'uuid';
import formDifferentiation from './FormType.js';
import './Trees.css';

import Node from './sections/Node.js';

// NOTE: No local trees object required here since we are now fetching from the database

// ADD CONFIRMATION FOR LOGIN!!

const TreeRendererDB = (props) => {
  const navigate = useNavigate();
  const location = useLocation();

  const sigCanvasRef = useRef(null);

  // Define state variables
  // Removed static loading from file; these will come from the database
  const [node, setNode] = useState(null);
  const [currentTree, setCurrentTree] = useState(null);
  const [nodeHistory, setNodeHistory] = useState([]);
  const [modal, setModal] = useState(false);
  const [waitingOnBackEndResponse, setWaitingOnBackEndResponse] = useState(false);
  const [renderedForm, setRenderedForm] = useState([]);
  const [renderedButtons, setRenderedButtons] = useState([]);
  const [sessionId, setSessionId] = useState();
  const [formData, setFormData] = useState({});
  const [formFields, setFormFields] = useState({});
  const [user, setUser] = useState('');

  useEffect(() => {
    // fetch('/api/auth/status')
    //     .then(response => response.json())
    //     .then(data => {
    //       if (data.authenticated) {
    //         setUser(data.user)
    //         // fetchWorkSchedule(currentDate); // Fetch work schedules for the initial date on page load
    //       } else {
    //         window.location.href = '/login';
    //       }
    //     });
    // }, []);
    
    window.scroll(0, 0);
    // console.log('props.startingTreeId:', props.startingTreeId);
    
    // Fetch the first node dynamically from the database using props.startingTreeId and props.startingNodeId
    // This replaces the static loading of the node from a local file
    fetch('/api/admin/getFirstNode', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ treeId: props.startingTreeId })
    })
      .then(response => response.json())
      .then(data => {
        // console.log('data:', data);
        const newRenderedButtons = buttonRender(data.firstNode.buttons);
        const newRenderedForm = formRender();
        setFormData({ ...data.firstNode.formData });
        setFormFields(data.firstNode.formFields || {});
        setRenderedButtons(newRenderedButtons)
        setRenderedForm(newRenderedForm);
        setNode(data.firstNode);
        setCurrentTree(data.treeData);
        setSessionId(v4());
      });

    const handlePopState = (e) => {
      e.preventDefault();
      getPreviousNode();
    };

    window.addEventListener('popstate', handlePopState);
    return () => {
      window.removeEventListener('popstate', handlePopState);
    };
  }, [props.startingTreeId]);

  const clearSignature = () => {
    if (sigCanvasRef.current) {
      sigCanvasRef.current.clear();
    }
  };

  const saveSignature = () => {
    if (sigCanvasRef.current) {
      const signature = sigCanvasRef.current.toDataURL(); // Converts the signature to a Data URL
      setFormData({ signature }); // Set the signature in the form field
      setWaitingOnBackEndResponse(true); // Update the state to indicate loading
      const bodyJson = {
        formData: { signature, complete: true },
        session: sessionId,
        tree: currentTree.node.treeId,
        node: currentTree.node.node,
        button: 0,
        link: currentTree.link,
        bookingUuid: props.bookingData.uuid
      };
      fetch('/api/getNextNode/', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(bodyJson),
      })
        .then(response => response.json())
        .then(rsp => {
          if (rsp.link !== null) {
            navigate({
              pathname: rsp.link,
              state: { url: location.pathname }
            });
          }

          setFormData({});
          setWaitingOnBackEndResponse(false);
          props.closeModal();
          window.scroll(0, 0);
        });
    }
  };

  function getOrderedUuids (nodeHistory, formData) {
    const serviceUuidArray = [];

    // Reverse the array to start from the most recent node
    const reversedHistory = [...nodeHistory].reverse();

    for (const item of reversedHistory) {
      if (item.node.node === 2 || item.node.node === 22) {
        // We've found the dosage selection node
        const selectedService = item.selectedService;
        serviceUuidArray.push({ uuid: selectedService });

        // We've processed the dosage node, so we can break the loop
        break;
      }
    }

    // Check the deliveryType and add the specific service UUID if needed
    if (formData.deliveryType !== 'pickup') {
      // This adds the delivery fee service UUID
      serviceUuidArray.push({ uuid: '8b7183bc-62bd-471c-9385-947ab5dbc2b6' });
    }
    return serviceUuidArray;
  }

  const getNextNode = (selectedButton) => {
    const bodyJson = {
      formData,
      session: sessionId,
      tree: currentTree.id,
      node: node.node,
      button: selectedButton,
      link: currentTree.link,
      bookingUuid: props.bookingData.uuid
    };

    const listOfFieldKeys = Object.keys(formFields);
    const listOfFields = listOfFieldKeys.map(eachKey => formFields[eachKey]);
    const requiredFields = listOfFields.filter(eachField => eachField.required == '1');
    const requiredCheck = requiredFields.reduce((total, eachField) => {
      if (formData[eachField.name] == null) {
        return 1 + total;
      }
      return total;
    }, 0);

    if (requiredCheck === 0 && !waitingOnBackEndResponse) {
      setNodeHistory(prevHistory => [...prevHistory, {
        node,
        formData,
        formFields,
        selectedService: node.buttons[selectedButton].button_data // Store the selected button text

      }]);

      setWaitingOnBackEndResponse(true); // Update the state to indicate loading

      fetch('/api/getNextNode/', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(bodyJson),
      })
        .then(response => response.json())
        .then(rsp => {
          if (rsp.link == 'closeForm') {
            props.closeModal();
            window.scroll(0, 0);
          } else if (rsp.link !== null) {
            navigate({
              pathname: rsp.link,
              state: { url: location.pathname }
            });
          }

          const newRenderedButtons = buttonRender(rsp.buttons);
          const newRenderedForm = formRender();
          setFormData({});
          setFormFields(rsp.formFields);
          setRenderedButtons(newRenderedButtons);
          setRenderedForm(newRenderedForm);
          setNode(rsp)
          setWaitingOnBackEndResponse(false);
          window.scroll(0, 0);
        });

      // optional navigation to path
      // navigate(`${location.pathname}`);
    } else {
      setModal(!modal);
    }
  };

  const getPreviousNode = () => {
    const bodyJson = {
      nodesToGoBack, // not currently defined, current trees do not allow back navigation -- to implement, adding state function setNodesToGoBack will be necessary.
      session: sessionId,
    };

    fetch('/api/getPreviousNode/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(bodyJson),
    })
      .then(response => response.json())
      .then(rsp => {
      // Update state with the response
        setFormData({});
        setFormFields(rsp.formFields);
        setCurrentTree(rsp);
        const newRenderedButtons = buttonRender(rsp.buttons);
        setRenderedButtons(newRenderedButtons);
        setNodesToGoBack(prevNodesToGoBack => prevNodesToGoBack + 1); // Update nodesToGoBack
      });
  };

  useEffect(() => {
    const newRenderedForm = formRender();
    setRenderedForm(newRenderedForm);
  }, [formFields]);

  const buttonRender = (buttonData) => {
    if (!buttonData || Object.keys(buttonData).length === 0) {
      return []; // Return an empty array if no buttons
    }

    const listOfButtonNumbers = Object.keys(buttonData);
    const theButtons = listOfButtonNumbers.map((eachButtonNumber, index) => {
      const reactButtonContent = JSON.stringify(
        buttonData[eachButtonNumber].button_text
      ).replace(/"/g, '');
      const buttonDataAttribute = buttonData[eachButtonNumber].button_data || null;
      if (buttonData[eachButtonNumber].button_text.includes('Close Form')) {
        return (
          <div className="w-20 tree-btn-container" key={index}>
            <button className="tree-btn" onClick={() => {
              props.closeModal();
              window.scroll(0, 0);
            }}
            >
              {reactButtonContent}
            </button>
          </div>
        );
      } else {
        return (
          <div className="w-20 tree-btn-container" key={index}>
            <button className="tree-btn" onClick={() => getNextNode(eachButtonNumber)}
            data-button-data={buttonDataAttribute}
            >
              {reactButtonContent}
            </button>
          </div>
        );
      }
    });

    return theButtons;
  };

  const onFormInputChange = (e) => {
    const { type, checked, id, value, name, files } = e.target;

    if (type === 'checkbox') {
      setFormData(prevFormData => { return ({ ...prevFormData, [name]: checked }) });
    } else if (type === 'radio') {
      setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    } else if (type === 'file') {
      const uploadAsyncImage = async () => {
        const fileData = new FormData();
        const uniqueFile = files[0];
        // Append additional data if necessary
        fileData.append('title', id);
        fileData.append('file', uniqueFile); // Assuming you want to upload the original file

        // Handle file compression here if desired

        const response = await fetch('/api/uploadImage/', {
          method: 'POST',
          body: fileData,
        });
        const rsp = await response.json();
        // Handle the response as desired
      };
      uploadAsyncImage();
      setFormData(prevFormData => ({ ...prevFormData, [id]: files[0] })); // Store the file in formData
    } else {
      setFormData(prevFormData => ({ ...prevFormData, [id]: value }));
    }
  };

  const formRender = () => {
    const formFieldList = formFields;
    const listOfFormFieldsKeys = Object.keys(formFieldList);

    if (listOfFormFieldsKeys.length > 0) {
      return listOfFormFieldsKeys.map((eachFormKey) => {
        // Adjust the formDifferentiation function call as needed.
        // If formDifferentiation relies on 'this', you need to refactor it to work as a standalone function or hook.
        return formDifferentiation(eachFormKey, formFieldList, onFormInputChange, formData, formFields, sigCanvasRef, clearSignature, saveSignature);
      });
    }
  };

  const splitContent = node && node.content && node.content.split('##data-entry-fields##');
  const reactNodeContent1 = splitContent && splitContent[0];
  const reactNodeContent2 = splitContent && splitContent.length > 1 ? JSON.stringify(splitContent[1]) : null;
  const question = <div className="nodeQuestion">{JSON.stringify(node?.question)}</div>;

  return (
      <div id={'newrequesttrees'} style={{ maxWidth: '90vw', minHeight: '60vh' }}>
        <Mask className="d-flex justify-content-center responsive-align text-center white">
          <Container fluid>
            <Modal
              isOpen={modal}
              toggle={() => setModal(!modal)}
              className="text-center"
              centered
            >
              <ModalHeader toggle={() => setModal(!modal)}>Info Required</ModalHeader>
              <ModalBody>
                <strong>You must fill out the required fields to proceed.</strong> <br /> <br />
              </ModalBody>
              <Button color="danger" onClick={() => setModal(!modal)}>Close</Button>
            </Modal>

            <Modal
              isOpen={waitingOnBackEndResponse}
              className="text-center"
              centered
            >
              <ModalBody>
                <span style={{ fontWeight: '900' }}>... Calculating</span> <br />
              </ModalBody>
            </Modal>
            {node
              ? <Node
              reactNodeContent1={reactNodeContent1}
              reactNodeContent2={reactNodeContent2}
              question={question}
              buttons={node && node.buttons ? buttonRender(node.buttons) : []}
              form={renderedForm}
              treeName={props.startingTreeId} // this changed from props.treeName to props.startingTreeId, and the implementation of the prop now needs to be named accordingly
            />
              : <>Loading...</>}
          </Container>
        </Mask>
      </div>
  );
}

export default TreeRendererDB;
