import React, { useEffect, useState } from 'react';
import {
  Col,
  Container,
  Row,
  Mask
} from 'mdbreact';
import '../Trees.css';

const Node = props => {
  // console.log(props)
  const [reactNodeContent1, setReactNodeContent1] = useState(props.reactNodeContent1);
  const [reactNodeContent2, setReactNodeContent2] = useState(props.reactNodeContent2);
  const [form, setForm] = useState(props.form);
  const [question, setQuestion] = useState(props.question);
  const [buttons, setbuttons] = useState(props.buttons);

  useEffect(() => {
    setReactNodeContent1(props.reactNodeContent1);
    setReactNodeContent2(props.reactNodeContent2);
    setForm(props.form);
    setQuestion(props.question);
    setbuttons(props.buttons);
  }, [props.reactNodeContent1, props.reactNodeContent2, props.form, props.question, props.buttons]);
  return (
    <Mask className="d-flex justify-content-center responsive-align align-items-center white">
      <Container fluid className="my-0 mx-5">
        {/* <Col md="10" xl="6" className="offset-md-1 offset-xl-3"> */}
        <Row className="align-items-center mt-5" id={props.treeName}>
          <Col className="mx-0 px-0 mb-3">
            <div id="treeContent" />
            {/* {console.log(reactNodeContent1)}
            {console.log(reactNodeContent2)} */}
            <div dangerouslySetInnerHTML={{ __html: reactNodeContent1 }} />
            {/* {console.log(form)} */}
            <div className={'form-container' + (reactNodeContent1.includes("experience")? " questionnaire" : "")}>
            {form}</div>
            <div dangerouslySetInnerHTML={{ __html: reactNodeContent2 }} />
          </Col>
          {question && buttons && <div className="well" style={{ maxWidth: '95vw' }} >
            {/* {console.log(question)} */}
            {/* {console.log(buttons)} */}
            {/* {question} */}
            {buttons}
            {/* <Row style={{ justifyContent: "center" }}> */}

            {/* <div className="w-100">{buttons}</div> */}
            {/* </Row> */}
          </div>}

        </Row>
        {/* </Col> */}
      </Container>
    </Mask>
  );
};

export default Node;
