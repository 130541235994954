import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { formatDate } from '../utility/module.js';
import ReactDatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const CustomerServiceContent = ({ bookingData, handleFieldChange }) => {
  // Initialize state
  const [localBookingData, setLocalBookingData] = useState(bookingData);
  const [nurses, setNurses] = useState([]);
  const [cities, setCities] = useState([]);
  const [noteReason, setNoteReason] = useState('late');
  const [noteText, setNoteText] = useState('');
  const [cancelNoteReason, setCancelNoteReason] = useState('');
  const [cancelNoteText, setCancelNoteText] = useState('');
  const [user, setUser] = useState('');
  const [notes, setNotes] = useState(bookingData.customerServiceCommunicationJSON || []);
  const [adminNotes, setAdminNotes] = useState(bookingData.adminNotes || []);
  const [adminNoteText, setAdminNoteText] = useState('');
  const [savedBookingData, setSavedBookingData] = useState(bookingData);
  const [travelTime, setTravelTime] = useState(0);
  const [duration, setDuration] = useState(0);
  const [saveSuccess, setSaveSuccess] = useState(false);

  // Fetch user authentication status on mount
  useEffect(() => {
    fetch('/api/auth/status')
      .then(response => response.json())
      .then(data => {
        if (data.authenticated) {
          setUser(data.user);
        } else {
          window.location.href = '/login';
        }
      })
      .catch(error => {
        console.error('Error fetching auth status:', error);
        // Optionally, handle error (e.g., redirect to login)
      });
  }, []);

  // Update savedBookingData and localBookingData when bookingData prop changes
  useEffect(() => {
    setSavedBookingData(bookingData);
    setLocalBookingData(bookingData);
  }, [bookingData]);

  // Fetch nurses and cities on component mount
  useEffect(() => {
    fetchNursesAndCities();
  }, []);

  // Function to fetch nurses and cities
  const fetchNursesAndCities = async () => {
    try {
      const [fetchedNurses, fetchedCities] = await Promise.all([getNurses(), getCities()]);
      setNurses(fetchedNurses);
      setCities(fetchedCities);
    } catch (error) {
      console.error('Error fetching nurses and cities:', error);
      // Optionally, handle error (e.g., show notification)
    }
  };

  // Fetch nurses
  const getNurses = async () => {
    const response = await fetch('/api/admin/getStaff', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ role: 'nurse' }),
    });
    if (!response.ok) {
      throw new Error('Failed to fetch nurses');
    }
    return response.json();
  };

  // Fetch cities
  const getCities = async () => {
    const response = await fetch('/api/admin/getCityCalendars', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
    });
    if (!response.ok) {
      throw new Error('Failed to fetch cities');
    }
    return response.json();
  };

  // Handle generic field changes
  const handleChange = (event, field) => {
    if (field === 'startTime') {
      setLocalBookingData({ ...localBookingData, startTime: event });
      handleFieldChange(field, event);
    } else {
      setLocalBookingData({ ...localBookingData, [field]: event.target.value });
      handleFieldChange(field, event.target.value);
    }
  };

  // Handle toggling of nested boolean fields (e.g., 'patient.isVIP')
  const handleToggleChangeBoolean = (fieldPath) => {
    setLocalBookingData((prevData) => {
      const fieldParts = fieldPath.split('.');
      const updatedData = { ...prevData };
      let current = updatedData;

      // Traverse to the nested field, creating copies at each level
      for (let i = 0; i < fieldParts.length - 1; i++) {
        const part = fieldParts[i];
        if (!current[part]) {
          current[part] = {};
        } else {
          current[part] = { ...current[part] };
        }
        current = current[part];
      }

      const lastField = fieldParts[fieldParts.length - 1];
      current[lastField] = !current[lastField]; // Toggle the boolean value

      return updatedData;
    });
  };

  // Handle saving status changes with green flash
  const handleSave = (event) => {
    event.preventDefault(); // Prevent default form submission behavior

    const changes = {};

    // Deep comparison to detect changes
    const detectChanges = (saved, local, prefix = '') => {
      for (const key in local) {
        if (typeof local[key] === 'object' && local[key] !== null && !Array.isArray(local[key])) {
          detectChanges(saved[key] || {}, local[key], `${prefix}${key}.`);
        } else if (local[key] !== saved[key]) {
          changes[`${prefix}${key}`] = local[key];
        }
      }
    };

    detectChanges(savedBookingData, localBookingData);

    // If there are changes, send them to the backend
    if (Object.keys(changes).length > 0) {
      const updatedData = { uuid: localBookingData.uuid, ...changes };

      fetch('/api/admin/updateBooking', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(updatedData),
      })
        .then(response => {
          if (!response.ok) {
            throw new Error('Failed to update booking');
          }
          return response.json();
        })
        .then(data => {
          // Update savedBookingData with the latest changes
          setSavedBookingData(prev => ({
            ...prev,
            ...changes,
          }));

          // Update patient info after booking update
          updatePatientInfo();

          // Trigger the green flash
          setSaveSuccess(true);
          setTimeout(() => setSaveSuccess(false), 3000);
        })
        .catch(error => {
          console.error('Error updating booking with changes:', error);
          // Optionally, handle error state here (e.g., show error notification)
        });
    } else {
      console.log('No changes to save');
      // Optionally, notify the user that there are no changes
    }
  };

  // Update patient information (isVIP and blackList)
  const updatePatientInfo = () => {
    const { uuid } = localBookingData.patient;
    const { isVIP, blackList } = localBookingData.patient;

    fetch('/api/admin/updatePatient', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        uuid,
        isVIP,
        blackList,
      }),
    })
      .then(response => {
        if (!response.ok) {
          throw new Error('Failed to update patient info');
        }
        return response.json();
      })
      .then(data => {
        // Optionally, update state with response data if needed
      })
      .catch(error => {
        console.error('Error updating patient info:', error);
        // Optionally, handle error state here (e.g., show error notification)
      });
  };

  // Handle customer service note submission
  const handleNoteSubmit = (e) => {
    e.preventDefault();

    const newNote = {
      reason: noteReason,
      text: noteText,
      date: new Date().toISOString(),
      author: user.email,
      provider: bookingData.primaryStaffId,
    };
    setNotes([...notes, newNote]);

    // Clear input fields
    setNoteReason('late'); // Reset to default value
    setNoteText('');

    // Append new note to existing JSON and update
    const updatedNotes = bookingData.customerServiceCommunicationJSON
      ? [...bookingData.customerServiceCommunicationJSON, newNote]
      : [newNote];

    fetch('/api/admin/updateBooking', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        uuid: localBookingData.uuid,
        customerServiceCommunicationJSON: updatedNotes,
      }),
    })
      .then(response => {
        if (!response.ok) {
          throw new Error('Failed to update booking with new note');
        }
        return response.json();
      })
      .then(data => {
        // Optionally, update state with response data if needed
      })
      .catch(error => {
        console.error('Error updating booking with new note:', error);
        // Optionally, handle error state here (e.g., show error notification)
      });
  };

  // Handle admin note submission
  const handleAdminNoteSubmit = (e) => {
    e.preventDefault();

    const newAdminNote = {
      date: new Date().toISOString(),
      text: adminNoteText,
      author: user.email, // Assuming the user object contains an email field
    };
    const updatedAdminNotes = [...adminNotes, newAdminNote];

    fetch('/api/admin/updateBooking', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        uuid: localBookingData.uuid,
        adminNotes: updatedAdminNotes,
      }),
    })
      .then(response => {
        if (!response.ok) {
          throw new Error('Failed to update admin notes');
        }
        return response.json();
      })
      .then(data => {
        setAdminNotes(updatedAdminNotes); // Update local state with new list of notes
        setAdminNoteText(''); // Clear admin note input field
      })
      .catch(error => {
        console.error('Error updating admin notes:', error);
        // Optionally, handle error state here (e.g., show error notification)
      });
  };

  // Render admin notes section
  const renderAdminNoteSection = () => {
    if (user.acl > 2) {
      return (
        <div className='adminTextField'>
          <h3>Admin Notes</h3>
          <p>
            Only viewable to admins. Use for notes that are not customer service issues.
          </p>
          {adminNotes.length > 0 && (
            <div className='adminTextField'>
              <table>
                <thead>
                  <tr>
                    <th>Date</th>
                    <th>Note</th>
                    <th>Author</th>
                  </tr>
                </thead>
                <tbody>
                  {adminNotes.map((note, index) => (
                    <tr key={index}>
                      <td>{formatDate(note.date)}</td>
                      <td>{note.text}</td>
                      <td>{note.author}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
          <form onSubmit={handleAdminNoteSubmit}>
            <textarea
              value={adminNoteText}
              onChange={(e) => setAdminNoteText(e.target.value)}
              rows={4}
              placeholder="Add a new admin note..."
              required
            />
            <br />
            <button type="submit">Save Admin Note</button>
          </form>
        </div>
      );
    }
    return null;
  };

  // Handle booking cancellation
  const cancelBooking = (e) => {
    e.preventDefault(); // Prevent default form submission behavior
    const button = e.target;

    const newNote = {
      reason: cancelNoteReason,
      text: cancelNoteText,
      date: new Date().toISOString(),
      author: user.email,
      provider: bookingData.primaryStaffId,
    };
    setNotes([...notes, newNote]);

    // Clear input fields
    setCancelNoteReason('');
    setCancelNoteText('');

    // Append new note to existing JSON and update
    const updatedNotes = bookingData.customerServiceCommunicationJSON
      ? [...bookingData.customerServiceCommunicationJSON, newNote]
      : [newNote];

    fetch('/api/admin/updateBooking', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        uuid: localBookingData.uuid,
        customerServiceCommunicationJSON: updatedNotes,
      }),
    })
      .then(response => {
        if (!response.ok) {
          throw new Error('Failed to update booking with cancellation note');
        }
        return response.json();
      })
      .then(data => {
        // Optionally, update state with response data if needed
      })
      .catch(error => {
        console.error('Error updating booking with cancellation note:', error);
        // Optionally, handle error state here (e.g., show error notification)
      });

    fetch('/api/admin/cancelBooking', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        uuid: localBookingData.uuid,
        reason: cancelNoteReason || 'Cancelled by user', // Use the selected reason
      }),
    })
      .then(response => {
        if (!response.ok) {
          throw new Error('Failed to cancel booking');
        }
        return response.json();
      })
      .then(data => {
        // Apply green flash effect
        button.classList.add('green');
        setTimeout(() => button.classList.remove('green'), 3000);
        // Optionally, update state or redirect as needed
      })
      .catch(error => {
        console.error('Error cancelling booking:', error);
        // Optionally, handle error state here (e.g., show error notification)
      });
  };

  // Handle moving the booking
  const moveBooking = (nurseId, cityId, newStartTime, e) => {
    e.preventDefault(); // Prevent default form submission behavior
    const button = e.target;

    const bodyJSON = {
      uuid: localBookingData.uuid,
      nurseId: Number(nurseId),
      cityId,
      newStartTime,
    };

    fetch('/api/admin/moveBooking', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(bodyJSON),
    })
      .then(response => {
        if (!response.ok) {
          throw new Error('Failed to move booking');
        }
        return response.json();
      })
      .then(async data => {
        // Apply green flash effect
        button.classList.add('green');
        setTimeout(() => {
          button.classList.remove('green');
          window.location.reload();
        }, 3000)
      })
      .catch(error => {
        console.error('Error moving booking:', error);
        // Optionally, handle error state here (e.g., show error notification)
      });
  };

  // Handle changing calendar block duration
  const changeCalendarBlockDuration = (travelTime, duration, e) => {
    e.preventDefault(); // Prevent default form submission behavior
    const button = e.target;

    const newStartTime = moment(localBookingData.startTime)
      .subtract(travelTime, 'minute')
      .toISOString();
    const newEndTime = moment(newStartTime).add(duration, 'minute').toISOString();

    fetch('/api/admin/updateCalendarBlock', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        uuid: localBookingData.calendarBlockUuid,
        startTime: newStartTime,
        endTime: newEndTime,
      }),
    })
      .then(response => {
        if (!response.ok) {
          throw new Error('Failed to update calendar block');
        }
        return response.json();
      })
      .then(data => {
        // Apply green flash effect
        button.classList.add('green');
        setTimeout(() => button.classList.remove('green'), 3000);
        // Reload page or update state as needed
        window.location.reload();
      })
      .catch(error => {
        console.error('Error updating calendar block:', error);
        // Optionally, handle error state here (e.g., show error notification)
      });
  };

  // Generate the minimum datetime-local input string
  const getMinDateTime = () => {
    const now = new Date();
    now.setMinutes(Math.ceil(now.getMinutes() / 30) * 30); // Round up to the next 30-minute interval
    now.setSeconds(0);
    now.setMilliseconds(0);
    return now.toISOString().slice(0, 16); // Convert to datetime-local input format
  };

  return (
    <div className='adminParent adminTabSection'>
      {/* Cancel Booking Section */}
      <div className='adminTextField'>
        <h3>Cancel Booking</h3>
        <form onSubmit={cancelBooking}>
          <label>
            Reason:{' '}
            <select
              value={cancelNoteReason}
              onChange={(e) => {
                setCancelNoteText(e.target.options[e.target.selectedIndex].id);
                setCancelNoteReason(e.target.value);
              }}
              required
            >
              <option value="">Please select a reason</option>
              <option value="Nurse missed" id="Nurse missed">
                Nurse missed IV and patient canceled
              </option>
              <option value="Patient Canceled - late" id="Patient Canceled - late">
                Patient canceled because we were running late
              </option>
              <option value="Patient canceled due to travel fee" id="Patient canceled due to travel fee">
                Patient canceled due to travel fee
              </option>
              <option value="Patient canceled - favorite nurse unavailable" id="Patient canceled - favorite nurse unavailable">
                Patient canceled - favorite nurse unavailable
              </option>
              <option value="Bad Patient" id="Rambunctious Patient">
                Luxe - Canceled because we do not like the patient
              </option>
              <option value="Outside Service Area" id="Outside Service Area">
                Outside service area
              </option>
              <option value="Patient unreachable at time of appointment" id="Patient unreachable at time of appointment">
                Patient unreachable at time of appointment
              </option>
              <option value="Patient Health History" id="Patient Health History">
                Patient health history doesn't allow drip
              </option>
              <option value="Patient unreachable to confirm before appointment" id="Patient unreachable to confirm before appointment">
                Patient unreachable to confirm before appointment
              </option>
              <option value="test booking" id="test booking">
                Test booking
              </option>
              <option value="Patient Canceled - other - Note required" id="Patient Canceled - other">
                Patient canceled - other reason (please specify in notes)
              </option>
              <option value="Wrong Patient" id="Wrong Patient (Recreated under new name)">
                Wrong Patient (Recreated under new name)
              </option>
            </select>
          </label>
          <br />
          <button type="submit" disabled={!cancelNoteReason}>
            Cancel Booking
          </button>
        </form>
      </div>

      {/* Change Booking Status Section */}
      <div className="toggle-button">
        <div className='adminTextField'>
          <h3>Change Booking Status</h3>
          <label>
            Customer Service Pending:
            <input
              type="checkbox"
              checked={localBookingData.customerServiceIssuePending}
              onChange={() => handleToggleChangeBoolean('customerServiceIssuePending')}
            />
            <span className="slider"></span>
          </label>
          <div className="toggle-button">
            <label>
              Blacklist Customer:
              <input
                type="checkbox"
                checked={localBookingData.patient.blackList}
                onChange={() => handleToggleChangeBoolean('patient.blackList')}
              />
              <span className="slider"></span>
            </label>
          </div>

          <div className="toggle-button">
            <label>
              VIP Customer:
              <input
                type="checkbox"
                checked={localBookingData.patient.isVIP}
                onChange={() => handleToggleChangeBoolean('patient.isVIP')}
              />
              <span className="slider"></span>
            </label>
            <br />
            <button
              onClick={handleSave}
              className={saveSuccess ? 'green' : ''}
              type="button" // Ensure it's a button and not a submit button
            >
              Save Status Change
            </button>
          </div>
        </div>
      </div>

      {/* Status Alerts */}
      {localBookingData.patient.blackList && (
        <div className="alert alert-danger">Customer is Blacklisted</div>
      )}
      {localBookingData.patient.isVIP && (
        <div className="alert alert-success">VIP Customer</div>
      )}

      {/* Move Appointment Section */}
      <div className='adminTextField'>
        <h3>Move Appointment</h3>
        <form onSubmit={(e) => moveBooking(localBookingData.primaryStaffId, localBookingData.cityCalendarId, localBookingData.startTime, e)}>
          <label>
            Time:
            <ReactDatePicker
              selected={localBookingData.startTime ? new Date(localBookingData.startTime) : null}
              onChange={(date) => handleChange(date, 'startTime')}
              showTimeSelect
              timeIntervals={15} // 15-minute intervals
              timeFormat="hh:mm a"
              dateFormat="yyyy-MM-dd hh:mm a"
              minDate={new Date()} // Set the minimum date to the current date
              placeholderText="Change Time"
              required
            />
          </label>
          <br />
          <label>
            Nurse:
            <select
              value={localBookingData.primaryStaffId}
              onChange={(e) => handleChange(e, 'primaryStaffId')}
              required
            >
              {nurses.map((nurse) => (
                <option key={nurse.id} value={nurse.id}>
                  {nurse.firstName} {nurse.lastName}
                </option>
              ))}
            </select>
          </label>
          {'  '}
          <label>
            City:
            <select
              value={localBookingData.cityCalendarId}
              onChange={(e) => {
                const userConfirmed = window.confirm("Are you sure you want to change the city?");
                if (userConfirmed) {
                  handleChange(e, 'cityCalendarId');
                }
              }}
              required
            >
              <option value="">Select a city</option>
              {cities.map((city) => (
                <option key={city.id} value={city.id}>
                  {city.name}
                </option>
              ))}
            </select>
          </label>
          <button type="submit">
            Move Booking
          </button>
        </form>
      </div>

      {/* Change Calendar Block Duration Section */}
      <div className='adminTextField'>
        <h3>Change Calendar Block Duration</h3>
        <form onSubmit={(e) => changeCalendarBlockDuration(travelTime, duration, e)}>
          <label>
            Add time before appointment (minutes):{' '}
            <select
              onChange={(e) => setTravelTime(Number(e.target.value))}
              value={travelTime}
              required
            >
              {[...Array(10)].map((_, index) => (
                <option key={index} value={index * 15}>
                  {index * 15} minutes
                </option>
              ))}
            </select>
          </label>
          <br />
          <label>
            Duration (minutes):{' '}
            <select
              onChange={(e) => setDuration(Number(e.target.value))}
              value={duration}
              required
            >
              {[...Array(12)].map((_, index) => (
                <option key={index} value={(index + 1) * 15}>
                  {(index + 1) * 15} minutes
                </option>
              ))}
            </select>
          </label>
          <button type="submit">
            Submit Time Change
          </button>
        </form>
      </div>

      {/* Admin Notes Section */}
      {renderAdminNoteSection()}

      {/* Customer Service Notes Section */}
      <div>
        <h3>Customer Service Notes</h3>
        <hr />
        <p>Existing notes</p>
        {notes.length > 0 && (
          <div className='adminTextField'>
            <table>
              <thead>
                <tr>
                  <th>Date</th>
                  <th>Reason</th>
                  <th>Note</th>
                  <th>Author</th>
                </tr>
              </thead>
              <tbody>
                {notes.map((note, index) => (
                  <tr key={index}>
                    <td>{formatDate(note.date)}</td>
                    <td>{note.reason}</td>
                    <td>{note.text}</td>
                    <td>{note.author}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
        <p>Add New Note</p>
        <div className='adminTextField'>
          <form onSubmit={handleNoteSubmit}>
            <label>
              Reason:{' '}
              <select
                value={noteReason}
                onChange={(e) => setNoteReason(e.target.value)}
                required
              >
                <option value="late">Late</option>
                <option value="nurse Issue">Nurse Issue other than stick</option>
                <option value="missed Stick">Missed stick</option>
                <option value="scheduling Impossible">Scheduling impossible</option>
                <option value="difficult Patient">Difficult Patient</option>
                <option value="Technical problem">Technical problem</option>
              </select>
            </label>
            <br />
            <br />
            <label>
              Note: <br />
              <textarea
                value={noteText}
                onChange={(e) => setNoteText(e.target.value)}
                required
              />
            </label>
            <br />
            <button type="submit">Submit Note</button>
          </form>
        </div>
      </div>

      {/* Additional Content */}
    </div>
  );
};

export default CustomerServiceContent;
