// TreeSessionViewer.js
import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import SessionDetails from './SessionDetails.js';
import RenderSessionSummary from './SessionSummary.js';
import './TreeSessionViewer.css';

// called when user wants to see the details of the associated forms attached to a record
const TreeSessionViewer = () => {
  const { bookingUUID } = useParams();
  const [user, setUser] = useState('');
  // const [trees, setTrees] = useState([]);
  const [sessions, setSessions] = useState([]);
  const [nodesMap, setNodesMap] = useState({});
  const navigate = useNavigate();

  useEffect(() => {
    fetch('/api/auth/status')
      .then(response => response.json())
      .then(data => {
        if (data.authenticated) {
          setUser(data.user);
        } else {
          window.location.href = '/login';
        }
      })
      .then(() => {
        fetch('/api/admin/getTreeSessionByBookingUuid', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            bookingUuid: bookingUUID,
          }),
        })
          .then(response => response.json())
          .then(data => {
            fetch('/api/admin/getTrees', {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
            })
              .then(response => response.json())
              .then(treeList => {
                // setTrees(treeList);
                // console.log('trees', treeList);
                // console.log('tree session data', data);
                // console.log('user acl', user.acl);
                let updatedTreeSession = data.map(eaSession => {
                  return {
                    ...eaSession,
                    treeName: treeList.find(tree => tree.id === eaSession.treeId)?.treeName || 'Unknown'
                  }
                })
                updatedTreeSession.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
                if (user.acl < 4) {
                  updatedTreeSession = updatedTreeSession.filter(session => session.treeId !== 6);
                }
                // console.log('updatedTreeSession', updatedTreeSession);
                setSessions(updatedTreeSession);
              });
          });
      });
  }, []);

  return (
    <div className="adminParent">
      <h1>Form Data Viewer</h1>
      {sessions.map((treeSession, index) => (
        <div className="adminTextField" key={index}>
          <RenderSessionSummary
            treeSession={treeSession}
          />
          <SessionDetails
            treeSession={treeSession}
            nodesMap={nodesMap}
          />
        </div>
      ))}
      <button onClick={() => navigate(`/admin/bookingNew/${bookingUUID}`)}>
        Back
      </button>
    </div>
  );
};

export default TreeSessionViewer;