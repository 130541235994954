import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import './scheduleStyles.css'
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const localizer = momentLocalizer(moment);

const MonochromeStaffAvailabilityCalendar = ({ userDetails }) => {
  const [schedule, setSchedule] = useState([]);
  const navigate = useNavigate();
  const [bookings, setBookings] = useState([]);
  const { cityId } = useParams()
  const [scheduleData, setScheduleData] = useState([]);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [currentDate, setCurrentDate] = useState(new Date());
  const [nextRefresh, setNextRefresh] = useState(moment().add(5, 'minutes').toISOString()); // [nextRefresh, setNextRefresh
  const [nextRefreshSeconds, setNextRefreshSeconds] = useState(300); // [nextRefresh, setNextRefresh


  // const [lastRefreshed, setLastRefreshed] = useState(moment()); // Store as moment object
  // const [nextRefresh, setNextRefresh] = useState(moment().add(5, 'minutes')); // Store as moment object
  
  const handleDateChange = (date) => {
    setCurrentDate(date);
  };

  useEffect(() => {
    // Check user's authentication status when component mounts
    // This could be a call to your server or checking local storage/session storage for a valid token
    fetch('/api/auth/status')
      .then(response => response.json())
      .then(data => {
        if (data.authenticated) {
          setIsAuthenticated(true);
        } else {
          // If not authenticated, redirect to login
          window.location.href = '/login';
        }
      });
    fetchSchedule(currentDate);
  }, []);

  useEffect(() => {
    const timer = setInterval(() => {
      const now = moment();
      const nextRefreshMoment = moment(nextRefresh);

      const differenceInSeconds = nextRefreshMoment.diff(now, 'seconds');
      setNextRefreshSeconds(differenceInSeconds);
      // console.log('Time until next refresh (minutes:seconds):', timeUntilNextRefresh);
      // console.log('Time until next refresh (seconds):', differenceInSeconds);

      if (differenceInSeconds <= 0) {
        // Perform the refresh
        window.location.reload();
      }
    }, 1000); // Run every second

    return () => clearInterval(timer);
  }, [nextRefresh]); // Re-run if `nextRefresh` changes

  useEffect(() => {
    const timer = setTimeout(() => {
      window.location.reload();
    }, 300000); // 300000 milliseconds = 5 minutes
    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    fetchSchedule(currentDate);
  }, [currentDate]);

  const fetchSchedule = async (date) => {
    try {
      const response = await fetch('/api/admin/getWorkSchedule', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          date,
          city: cityId, // replace with real city reference
        }),
      });
      const data = await response.json();
      // sort by data.staff.firstName
      data.sort((a, b) => a.staff.firstName.localeCompare(b.staff.firstName));
      const markedData = data.map(eachStaffCalendar => {
        return {
          ...eachStaffCalendar,
          staff: {
            ...eachStaffCalendar.staff,
            calendarBlocks: eachStaffCalendar.staff.calendarBlocks.map(eachCalendarBlock => {
              if (eachCalendarBlock.booking && eachCalendarBlock.booking.patientMedicalNotes &&
                    eachCalendarBlock.booking.patientMedicalNotes.ivGauge &&
                    (eachCalendarBlock.booking.patientMedicalNotes.ivGauge.includes('24') ||
                        eachCalendarBlock.booking.patientMedicalNotes.ivGauge.includes('22'))) {
                return { ...eachCalendarBlock, booking: { ...eachCalendarBlock.booking, bookingProblem: true } };
              } else if
              (eachCalendarBlock.booking &&
                (
                  (
                    (!eachCalendarBlock.booking.extraStuff || !eachCalendarBlock.booking.extraStuff.nurseArrivalTime) &&
                        (new Date() - new Date(eachCalendarBlock.booking.startTime) > 900000)
                  ) ||
                    (
                      eachCalendarBlock.booking.extraStuff &&
                        eachCalendarBlock.booking.extraStuff.nurseArrivalTime &&
                        (new Date(eachCalendarBlock.booking.extraStuff.nurseArrivalTime) - new Date(eachCalendarBlock.booking.startTime) > 900000)
                    )
                )
              ) {
                return { ...eachCalendarBlock, booking: { ...eachCalendarBlock.booking, bookingProblem: true } };
              } else {
                return eachCalendarBlock;
              }
            })
          }
        };
      });

      // console.log('data', data)
      // console.log('markedData', markedData)
      if (response.ok) {
        setScheduleData(markedData)
        setSchedule(processScheduleData(markedData));
      } else {
        throw new Error('Network response was not ok.');
      }
    } catch (error) {
      console.error('Error fetching work schedule:', error);
    }
  };

  const isWithinWorkHours = (timeSlot, startTime, endTime) => {
    return timeSlot.isBetween(moment(startTime), moment(endTime), null, '[]');
  };

  const processScheduleData = (data) => {
    // console.log(data, currentDate)
    const staffSchedules = data.reduce((acc, item) => {
      const staffName = `${item.staff.firstName} ${item.staff.lastName}`;
      if (!acc[staffName]) acc[staffName] = [];

      item.staff.calendarBlocks.forEach(block => {
        // console.log(item.staff.extraStuff?.color)
        // console.log('item from calendarBlocks', block)
        acc[staffName].push({
          startTime: moment(block.startTime),
          endTime: moment(block.endTime),
          bookingId: (block.booking ? block.booking.uuid : null),
          title: (block.booking ? `${block.booking.patient.firstName}` : block.source),
          color: item.staff.extraStuff?.color,
          address: (block.booking ? block.booking.address1 : null),
          city: (block.booking ? block.booking.city : null),
          bookingProblem: (block.booking ? block.booking.bookingProblem : null),
          blackList: (block.booking ? block.booking.patient.blackList : null)
        });
      });

      return acc;
    }, {});

    return staffSchedules;
  };

  const handleCellClick = (bookingId) => {
    navigate(`/admin/bookingNew/${bookingId}`);
  };

  const hexToRGBA = (hex, opacity) => {
    const r = parseInt(hex.slice(1, 3), 16);
    const g = parseInt(hex.slice(3, 5), 16);
    const b = parseInt(hex.slice(5, 7), 16);

    return `rgba(${r}, ${g}, ${b}, ${opacity})`;
  };

  const getBookingDetails = (timeSlot, staffSchedule) => {
    const booking = staffSchedule.find(block =>
      timeSlot.isBetween(block.startTime, block.endTime, null, '[]')
    );
    return booking || {};
  };

  const getRelevantBookingsForSlot = (timeSlot, staffSchedule) => {
    // console.log('staffSchedule', staffSchedule)
    // console.log('timeSlot', timeSlot)
    return staffSchedule.filter(block => {
      return (timeSlot.isSameOrAfter(block.startTime, 'minute') &&
      timeSlot.isBefore(block.endTime, 'minute'))
    }
    );
  };

  const navigateDay = (direction) => {
    setCurrentDate(prevDate => {
      const newDate = moment(prevDate);
      if (direction === 'forward') {
        newDate.add(1, 'days');
      } else if (direction === 'backward') {
        newDate.subtract(1, 'days');
      }
      fetchSchedule(newDate.toDate()); // Fetch new schedule for the updated date
      return newDate.toDate();
    });
  };

  const createTimeSlots = (currentDate) => {
    const slots = [];
    const startOfDay = moment(currentDate).startOf('day');
    const time = startOfDay.clone().add(6, 'hours').add(15, 'minutes'); // Start at 6:30 AM
    // stop at 11pm
    while (time.format('hh:mm A') !== '11:00 PM') {
      slots.push(time.clone());
      time.add(15, 'minutes');
    }

    return slots;
  };
  const timeSlots = createTimeSlots(currentDate);

  const isTimeSlotBooked = (timeSlot, staffSchedule) => {
    return staffSchedule.some(block =>
      timeSlot.isBetween(block.startTime, block.endTime, null, '[]')
    );
  };

  return (
    <div className="admin-calendar-container">
      <div className='adminPageTitle'>
        <h1>Calendar block view</h1>
        <p>Note calendar blocks include appointment time and drive time <br/>
        Next page refresh: {nextRefreshSeconds} Seconds</p>
      </div>
      <div className="calendar-navigation">
      <button onClick={() => navigateDay('backward')} className="navigate-button">-</button>
      <DatePicker
        selected={currentDate}
        onChange={handleDateChange}
        dateFormat="MMMM d, yyyy"
        className="date-picker"
      />
      <button onClick={() => navigateDay('forward')} className="navigate-button">+</button>
    </div>

      <table className="scheduleTable">
        <thead>
          <tr>
            <th style={{ minWidth: '75px' }}>Time</th>
            {Object.keys(schedule).map((staffName, index) => (
              <th key={index}>{staffName}</th>
            ))}
          </tr>
        </thead>
        <tbody id="scheduleTable">
        {timeSlots.map((slot, index) => (
  <tr key={index}>
    <td>{slot.format('hh:mm A')}</td>
    {Object.entries(schedule).map(([staffName, staffSchedule], idx) => {
      const staffWorkHours = scheduleData.find(item => `${item.staff.firstName} ${item.staff.lastName}` === staffName);
      const isSlotWithinWorkHours = staffWorkHours && isWithinWorkHours(slot, staffWorkHours.startTime, staffWorkHours.endTime);
      const bookingTime = moment(slot);
      const minutesInDay = 24 * 60;
      const minutesSinceMidnight = bookingTime.diff(bookingTime.clone().startOf('day'), 'minutes');
      const percentageOfDay = (minutesSinceMidnight / minutesInDay) * 100;
      // console.log('slot', slot.format('hh:mm A'))
      const adjustedColor = `rgba(24, 171, 72, ${Math.min(1, percentageOfDay / 100)})`; // Adjust RGB values for your desired shade of green
      const relevantBookings = getRelevantBookingsForSlot(slot, staffSchedule);
      // console.log(relevantBookings)
      const hasCustomerServiceProblem = relevantBookings.some(booking => booking.bookingProblem);
      // go through relevant bookings and check if patient is blacklisted
      const blackListed = relevantBookings.some(booking => booking.blackList);
      const cellColor = blackListed ? 'black' : (hasCustomerServiceProblem ? 'rgba(255, 0, 0, 0.75)' : (isSlotWithinWorkHours ? adjustedColor : 'grey'));

      if (relevantBookings.length > 0) {
        return (
          <td
            key={idx}
            style={{ backgroundColor: cellColor, borderColor: adjustedColor }}
          >
    {relevantBookings.map((booking, bIdx) => (
  <div key={bIdx}
  >
    {slot.isSame(booking.startTime, 'minute') && (
      <a href={`/admin/bookingNew/${relevantBookings[bIdx].bookingId}`}>
        <div className='scheduleTableItem'>{booking.title}<br/>
        {booking.address}<br/>
        {booking.city}</div>
      </a>
    )}
  </div>
    ))}
          </td>
        );
      } else {
        return <td key={idx} style={{ backgroundColor: isSlotWithinWorkHours ? 'transparent' : 'grey' }}></td>;
      }
    })}
  </tr>
        ))}
</tbody>
      </table>

      <div className="calendar-bottom-space">
        <button onClick={() => navigate('/admin/byCity/' + cityId)} className="navigate-button">
          Back
        </button>
      </div>
    </div>
  );
};

export default MonochromeStaffAvailabilityCalendar;
