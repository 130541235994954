// TreeSessionViewer.js
import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import SessionDetails from './SessionDetails.js';
import RenderSessionSummary from './SessionSummary.js';
import './TreeSessionViewer.css';

// called when user wants to see the details of the associated forms attached to a record
const TreeSessionViewer = ({bookingUuid }) => {
  // const [trees, setTrees] = useState([]);
  const [sessions, setSessions] = useState([]);

  useEffect(() => {
    fetch('/api/admin/getTreeSessionByBookingUuidLight', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        bookingUuid,
      }),
    })
      .then(response => response.json())
      .then(data => {
        fetch('/api/admin/getTrees', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
        })
          .then(response => response.json())
          .then(treeList => {
            // setTrees(treeList);
            console.log('trees', treeList);
            console.log('tree session data', data);
            // sort data by createdAt date with newest first
            data.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
            const updatedTreeSession = data.map(eaSession => {
              return {
                ...eaSession,
                treeName: treeList.find(tree => tree.id === eaSession.session.treeId)?.treeName || 'Unknown'
              }
            })
            // console.log('updatedTreeSession', updatedTreeSession);
            setSessions(updatedTreeSession);
          });
      });
  }, []);

  return (
    <div className="adminParent">
        <hr/>
      <h1>Attached Forms
        <button onClick={() => window.location.href = `/admin/bookingTreeSessions/${bookingUuid}`}>
          See Details
        </button>
        </h1>
      {sessions.map((treeSession, index) => (
        <div className="adminTextField" key={index}>
          <RenderSessionSummary
            treeSession={treeSession}
          />
        </div>
      ))}
    </div>
  );
};

export default TreeSessionViewer;